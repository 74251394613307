import React from "react"
import { graphql } from "gatsby"
import { Cards, Hero, SiteMetadata } from "../components"
import { Layout } from "../layouts/Layout"

export default ({ data }) => {
  return (
    <Layout>
      <SiteMetadata
        title="Letenky kamkoliv"
        description="Vydej se po Evropě kamkoliv. Levné letenky na jednom místě."
        image={data.hero.url}
      />

      <Hero
        image={data.hero}
        tag="#travel"
        title="Letenky kamkoliv"
        description="Vydej se kamkoliv po Evropě. Levné letenky kamkoliv si zamaneš na jednom místě."
      />

      <Cards nodes={data.items.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery($tableName: String!) {
    hero: file(relativePath: { eq: "hero-travel.jpg" }) {
      ...HeroImageFragment
    }
    items: allAirtable(
      filter: { data: { slug: { ne: null } }, table: { eq: $tableName } }
    ) {
      nodes {
        data {
          country
          image {
            ...CardImageFragment
          }
          name
          slug
          summary
        }
      }
    }
  }
`
